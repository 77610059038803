<template>
  <form
    id="client-profile"
    @submit.prevent="saveClient"
    class="form-prepo-et w-form"
  >
    <div class="input-group">
      <label class="field-label">Meno<strong></strong></label
      ><input
      v-model="form.firstName"
      name="firstName"
      type="text"
      class="text-input w-input"
      maxlength="256"
    />
    </div>
    <div class="input-group">
      <label class="field-label">Priezvisko<strong></strong></label
      ><input
      v-model="form.lastName"
      name="lastName"
      type="text"
      class="text-input w-input"
      maxlength="256"
    />
    </div>
    <div v-if="wrongEmail" class="red--text d-flex justify-content-center mb-1">Emailová adresa je už zabratá.</div>
    <div class="input-group">
      <label class="field-label">Email<strong></strong></label
      ><input
      v-model="form.email"
      name="email"
      type="email"
      class="text-input w-input"
      maxlength="256"
    />
    </div>
    <div class="input-group">
      <label class="field-label">Telefón<strong></strong></label
      ><input
      v-model.number="form.telNumber"
      name="telNumber"
      type="text"
      class="text-input w-input"
    />
    </div>
    <div class="dropdown-input-custom rest-input">
      <dropdown-select
        @select="setDropdownValue($event, 'maritalStatus')"
        :id="'rodinny_stav'"
        :init-value="form.maritalStatus"
        :options="formTemplate.rodinny_stav.choices"
        normal
        :name="'Rodinný stav'"
      />
    </div>
    <div class="input-group">
      <label class="field-label"
      >Rodné priezvisko<strong></strong></label
      ><input
      v-model="form.birthName"
      name="birthName"
      type="text"
      class="text-input w-input"
      maxlength="256"
    />
    </div>
    <div class="input-group">
      <label class="field-label"
      >Dátum narodenia<strong></strong
      ></label>
      <input
        name="dateOfBirth"
        v-model="form.dateOfBirth"
        type="date"
        :max="todayLong"
        class="text-input w-input date-input"
        maxlength="256"
      />
    </div>
    <div class="input-group">
      <label class="field-label"
      >Rodné číslo</label
      ><input
      v-model="form.birthNumber"
      name="placeOfBirth"
      type="text"
      class="text-input w-input"
      maxlength="256"
    />
    </div>
    <div class="input-group">
      <label class="field-label"
      >Miesto narodenia<strong></strong></label
      ><input
      v-model="form.placeOfBirth"
      name="placeOfBirth"
      type="text"
      class="text-input w-input"
      maxlength="256"
    />
    </div>
    <div class="input-group">
      <label class="field-label"
      >Štátna príslušnosť<strong></strong></label
      ><input
      name="nationality"
      v-model="form.nationality"
      type="text"
      class="text-input w-input"
      maxlength="256"
    />
    </div>
    <div class="input-group">
      <label class="field-label"
      >Číslo dokladu totožnosti<strong></strong></label
      ><input
      v-model="form.identityNumber"
      name="identityNumber"
      type="text"
      class="text-input w-input"
      maxlength="256"
    />
    </div>
    <div class="input-group">
      <label class="field-label">IBAN<strong></strong></label
      ><input
      name="iban"
      v-model="form.iban"
      type="text"
      class="text-input w-input"
      maxlength="256"
      minlength="8"
    />
    </div>
    <div class="input-group">
      <label class="field-label"
      >Expirácia dokladu<strong></strong
      ></label>
      <input
        v-model="form.identityDocumentExpiration"
        type="date"
        :min="todayLong"
        class="text-input w-input date-input"
        maxlength="256"
      />
    </div>
    <div class="input-group">
      <label class="field-label"
      >Dátum vydania dokladu<strong></strong
      ></label>
      <input
        v-model="form.op_vytvoreny"
        type="date"
        :max="todayLong"
        class="text-input w-input date-input"
        maxlength="256"
      />
    </div>
    <div class="section-title">
      <div class="text-small blc-color">Adresa trvalého pobytu</div>
    </div>
    <div class="dropdown-input-custom rest-input" style="margin-bottom: 8px">
      <dropdown-select
        :id="'permanent_address_country'"
        :init-value="form.country"
        :options="formTemplate.permanent_address_country.choices"
        normal
        :name="'Krajina'"
      />
    </div>
    <div class="input-group">
      <label for="supis" class="field-label">Obec<strong></strong></label
      ><input
      name="mainVillage"
      v-model="form.city"
      type="text"
      class="text-input w-input nested-input"
      maxlength="256"
      id="supis"
    />
    </div>
    <div class="input-group">
      <label class="field-label">Ulica<strong></strong></label
      ><input
      name="mainAvanue"
      v-model="form.street"
      type="text"
      class="text-input w-input"
      maxlength="256"
    />
    </div>
    <div class="input-group double-input-wrapper">
      <div class="nested-input-wrapper">
        <label class="field-label">Orientačné číslo<strong></strong></label
        ><input
        v-model="form.addressNumber"
        type="text"
        class="text-input w-input"
        maxlength="256"
      />
      </div>
      <div class="nested-input-wrapper">
        <label class="field-label">Súpisné číslo<strong></strong></label
        ><input
        v-model="form.addressPopisne"
        type="text"
        class="text-input w-input"
        maxlength="256"
      />
      </div>
    </div>
    <div class="input-group">
      <label class="field-label">PSČ<strong></strong></label
      ><input
      v-model="form.psc"
      type="number"
      class="text-input w-input"
      maxlength="256"
    />
    </div>
    <div class="section-title poniter noselect" @click="correspondenceAddressShown = !correspondenceAddressShown">
      <div class="bold bl-color">{{ !correspondenceAddressShown ? "+ pridať" : "- odobrať" }} korešpondenčnú adresu
      </div>
    </div>
    <div v-if="correspondenceAddressShown">
      <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="correspondenceAddress.country = $event"
          :id="'correspondence_address_country'"
          :init-value="correspondenceAddress.country"
          :options="formTemplate.correspondence_address_country.choices"
          normal
          :name="'Krajina'"
        />
      </div>
      <div class="input-group">
        <label class="field-label">Obec<strong></strong></label
        ><input
        name="mainVillage"
        v-model="correspondenceAddress.city"
        type="text"
        class="text-input w-input nested-input"
        maxlength="256"
      />
      </div>
      <div class="input-group">
        <label class="field-label">Ulica<strong></strong></label
        ><input
        name="mainAvanue"
        v-model="correspondenceAddress.street"
        type="text"
        class="text-input w-input"
        maxlength="256"
      />
      </div>
      <div class="input-group double-input-wrapper">
        <div class="nested-input-wrapper">
          <label class="field-label">Orientačné číslo<strong></strong></label
          ><input
          v-model="correspondenceAddress.addressNumber"
          type="text"
          class="text-input w-input"
          maxlength="256"
        />
        </div>
        <div class="nested-input-wrapper">
          <label class="field-label">Súpisné číslo<strong></strong></label
          ><input
          v-model="correspondenceAddress.addressPopisne"
          type="text"
          class="text-input w-input"
          maxlength="256"
        />
        </div>
      </div>
      <div class="input-group">
        <label class="field-label">PSČ<strong></strong></label
        ><input
        v-model="correspondenceAddress.psc"
        type="number"
        class="text-input w-input"
        maxlength="256"
      />
      </div>
    </div>
  </form>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";
import { patchUserProfileByID } from "@/services/brokerService";

export default {
  name: "PersonalInfoForm",
  components: {DropdownSelect},
  props: {
    client: Object,
  },
  created () {
    this.formTemplate = this.$store.getters["formStore/getFormByType"]("clientProfileForm");
    this.form.firstName = this.client.clientInfo.firstName;
    this.form.lastName = this.client.clientInfo.lastName;
    if (!this.client.clientInfo.email.includes('@nomail.bankari.sk')) {
      this.form.email = this.client.clientInfo.email;
    }
    this.form.op_vytvoreny = this.client.clientProfile.op_vytvoreny;
    this.form.telNumber = this.client.clientProfile.telephone;
    this.form.maritalStatus = this.client.clientProfile.rodinny_stav;
    this.form.birthName = this.client.clientProfile.rodne_priezvisko;
    this.form.dateOfBirth = this.client.clientProfile.datum_narodenia;
    this.form.birthNumber = this.client.clientProfile.rodne_cislo;
    this.form.placeOfBirth = this.client.clientProfile.miesto_narodenia;
    this.form.nationality = this.client.clientProfile.statna_prislusnost;
    this.form.identityNumber = this.client.clientProfile.op_cislo;
    this.form.iban = this.client.clientProfile.IBAN;
    this.form.identityDocumentExpiration = this.client.clientProfile.op_platnost_do;
    this.form.country = this.client.clientProfile.permanent_address_country;
    this.form.city = this.client.clientProfile.permanent_address_city;
    this.form.street = this.client.clientProfile.permanent_address_street;
    this.form.addressNumber = this.client.clientProfile.permanent_address_number;
    this.form.addressPopisne = this.client.clientProfile.permanent_address_popisne;
    this.form.psc = this.client.clientProfile.permanent_address_zip;
    this.correspondenceAddress.country = this.client.clientProfile.correspondence_address_country;
    this.correspondenceAddress.city = this.client.clientProfile.correspondence_address_city;
    this.correspondenceAddress.street = this.client.clientProfile.correspondence_address_street;
    this.correspondenceAddress.addressNumber = this.client.clientProfile.correspondence_address_number;
    this.correspondenceAddress.addressPopisne = this.client.clientProfile.correspondence_address_popisne;
    this.correspondenceAddress.psc = this.client.clientProfile.correspondence_address_zip;
    if (this.correspondenceAddress.country
      || this.correspondenceAddress.city
      || this.correspondenceAddress.street
      || this.correspondenceAddress.addressNumber
      || this.correspondenceAddress.addressPopisne
      || this.correspondenceAddress.psc) {
      this.correspondenceAddressShown = true;
    }
  },
  methods: {
    setDropdownValue (value, dropdownName) {
      this.form[dropdownName] = value;
    },
    async saveClient () {
      const clientProfile = {
        email: this.form.email,
        op_vytvoreny: this.form.op_vytvoreny,
        telephone: this.form.telNumber,
        rodinny_stav: this.form.maritalStatus,
        rodne_priezvisko: this.form.birthName,
        datum_narodenia: this.form.dateOfBirth === "" ? null : this.form.dateOfBirth,
        rodne_cislo: this.form.birthNumber,
        miesto_narodenia: this.form.placeOfBirth,
        statna_prislusnost: this.form.nationality,
        op_cislo: this.form.identityNumber,
        IBAN: this.form.iban,
        op_platnost_do: this.form.identityDocumentExpiration,
        permanent_address_country: this.form.country,
        permanent_address_city: this.form.city,
        permanent_address_street: this.form.street,
        permanent_address_number: this.form.addressNumber,
        permanent_address_popisne: this.form.addressPopisne,
        permanent_address_zip: this.form.psc,
        correspondence_address_country: this.correspondenceAddressShown ? this.correspondenceAddress.country : null,
        correspondence_address_city: this.correspondenceAddressShown ? this.correspondenceAddress.city : null,
        correspondence_address_street: this.correspondenceAddressShown ? this.correspondenceAddress.street : null,
        correspondence_address_number: this.correspondenceAddressShown ? this.correspondenceAddress.addressNumber : null,
        correspondence_address_popisne: this.correspondenceAddressShown ? this.correspondenceAddress.addressPopisne : null,
        correspondence_address_zip: this.correspondenceAddressShown ? this.correspondenceAddress.psc : null,
      };
      clientProfile.id = this.client.clientProfile.id;
      clientProfile.user = this.client.clientProfile.user;

      let response = null; // Declare `response` in the parent scope

      if (!this.client.clientInfo.firstName || !this.client.clientInfo.lastName || this.client.clientInfo.email.includes("@nomail.bankari.sk")) {
        let response = null;
        try {
          this.wrongEmail = false;
          response = await patchUserProfileByID(this.client.clientInfo.id, {
            email: this.form.email ? this.form.email : this.client.clientInfo.email,
            first_name: this.form.firstName,
            last_name: this.form.lastName
          });
          this.$emit("client-saved", {clientInfo: clientProfile, userProfile: response.data});
        } catch (e) {
          const error = e.toJSON();
          if (error.status === 400) {
            this.wrongEmail = true;
          }
        }
      } else {
        this.$emit("client-saved", {clientInfo: clientProfile});
        response = await patchUserProfileByID(this.client.clientInfo.id, {
          email: this.form.email ? this.form.email : this.client.clientInfo.email,
          first_name: this.form.firstName,
          last_name: this.form.lastName
        });
      }
    },
  },
  data: () => ({
    wrongEmail: false,
    todayLong: new Date().toISOString().substr(0, 10),
    todayShort: new Date().toISOString().substr(0, 7),
    formTemplate: null,
    form: {
      firstName: null,
      lastName: null,
      email: null,
      telNumber: null,
      maritalStatus: null,
      birthName: null,
      dateOfBirth: null,
      birthNumber: null,
      placeOfBirth: null,
      nationality: null,
      identityNumber: null,
      iban: null,
      identityDocumentExpiration: null,
      country: null,
      city: null,
      street: null,
      addressNumber: null,
      addressPopisne: null,
      psc: null,
    },
    correspondenceAddressShown: false,
    correspondenceAddress: {
      country: null,
      city: null,
      street: null,
      addressNumber: null,
      addressPopisne: null,
      psc: null,
    },
  }),
}
</script>

<style scoped></style>
