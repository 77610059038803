<template>
  <form @submit.prevent="saveProperty" id="property-submit">
    <div class="form-2">
      <div class="input-group">
        <label for="hodnota-nehnutelnosti" class="field-label"
        >Odhadovaná hodnota</label
        ><input
        v-model="form.hodnota"
        type="text"
        class="text-input w-input required"
        maxlength="256"
        name="hodnota-nehnutelnosti"
        id="hodnota-nehnutelnosti"
        required
      />
      </div>
      <div class="dropdown-input-custom rest-input" v-if="propertyTypeHelper === 'not_specified'">
        <dropdown-select
          @select="selectSubTypeOfProperty($event)"
          :id="'druh_nehnutelnosti'"
          :init-value="propertyTypes[0].value"
          :options="propertyTypes"
          normal
          :name="'Druh nehnuteľnosti'"
        />
      </div>
      <div class="dropdown-input-custom rest-input" v-if="subTypeData !== 'not_specified'">
        <dropdown-select
          @select="setDropdownValue($event, 'pravny_stav')"
          :id="'property_pravny_stav'"
          :init-value="propertyTypeHelper === 'not_specified' ? null : form.pravny_stav || getAccordingOptions()[0].value"
          :value-removal="removePravnyStavValue"
          @value-removed="removePravnyStavValue = $event"
          :options="getAccordingOptions()"
          normal
          :name="'Právny stav ' + type.label"
        />
      </div>
      <div class="splitter">Adresa {{ type.label }}</div>
      <sidlo-input
        @address-change="setAddress($event)"
        :google-place-id="googlePlaceId"
        :type="typeData"
        :not-required="druh === 'zakladane_nehnutelnosti' && typeData === 'land' ? {street: true} : null"
        :property-meta="propertyMeta"
        :not-built="form.pravny_stav === 'rozostavana'"
      />
      <div class="splitter">Vlastník {{ type.label }}</div>
      <div class="dropdown-input-custom rest-input">
        <dropdown-select
          @select="changeOwner($event)"
          :id="'property_typ_vlastnictva'"
          :init-value="translateOwnershipFromDbValue()"
          :options="getOwnerChoices()"
          normal
          :name="'Vlastník'"
        />
      </div>
      <div v-if="ownerHelper === 'other'">
        <client-picker
          @client-picked="setClient($event)"
          @client-created="createClient = $event"
          :client-init="otherOwnerId"
          :create-client="createClient"
          complex
        />
      </div>
      <div v-if="druh !== 'zatazene_nehnutelnosti'">
        <div class="splitter">Ťarchy {{ type.label }}</div>
        <div class="dropdown-input-custom rest-input">
          <input
            type="text"
            v-model="form.tarcha_banka"
            style="opacity: 0; position: absolute"
            :required="form.tarcha_orientacny_zostatok || form.tarcha_banka"
          />
          <dropdown-select
            @select="setDropdownValue($event, 'tarcha_banka')"
            :id="'property_tarcha_banky'"
            :init-value="form.tarcha_banka"
            :options="banks"
            normal
            :name="'Ťarcha banky'"
          />
        </div>
        <div class="input-group">
          <label for="orient-zost" class="field-label"
          >Orientačný zostatok</label
          ><input
          v-model.number="form.tarcha_orientacny_zostatok"
          type="number"
          step="0.01"
          class="text-input w-input"
          maxlength="256"
          name="orient-zost"
          placeholder=""
          id="orient-zost"
          :required="form.tarcha_orientacny_zostatok || form.tarcha_banka"
        />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import DropdownSelect from "../../../DropdownSelect";
import { mapGetters } from "vuex";
import SidloInput from "../../../SidloInput";
import ClientPicker from "../ClientPicker/ClientPicker";
import propertyFilters from "../../../../../mixins/propertyFilters";

export default {
  name: "PropertyForm",
  components: {ClientPicker, SidloInput, DropdownSelect},
  props: {
    druh: String,
    property: [Object, null],
    type: [Object, null],
    formTemplate: Object,
    banks: [Array, Object],
  },
  computed: {
    ...mapGetters({
      mortgageGetter: "mortgageDetailState/getMortgage",
    }),
  },
  watch: {
    mortgageGetter (newMortgage) {
      this.mortgage = newMortgage;
    },
  },
  mixins: [
    propertyFilters,
  ],
  created () {
    this.applicantId = this.$store.getters["mortgageDetailState/getApplicant"].id;
    this.coApplicantId = this.$store.getters["mortgageDetailState/getCoApplicant"]?.id;
    this.typeData = this.type.type;
    this.subTypeData = this.type.subtype;
    this.mortgage = this.$store.getters["mortgageDetailState/getMortgage"];
    if (this.property) {
      this.typeData = this.property.child_type ? this.property.child_type : "not_specified";
      this.propertyTypeHelper = this.property.child_type ? this.property.child_type : "not_specified";
      this.form.hodnota = this.property.hodnota;
      this.form.pravny_stav = this.property.pravny_stav;
      this.form.majitel = this.property.majitel;
      this.form.tarcha_banka = this.property.tarcha_banka;
      this.form.tarcha_orientacny_zostatok =
        this.property.tarcha_orientacny_zostatok;
      this.googlePlaceId = this.property.google_place_id;
      this.propertyMeta.cislo_lv = this.property.cislo_lv;
      this.propertyMeta.cislo_parcely = this.property.cislo_parcely;
      this.propertyMeta.address_text_number = this.property.address_text_number;
      this.propertyMeta.address_text_popisne = this.property.address_text_popisne;
      this.propertyMeta.address_text_county = this.property.address_text_county;
      this.propertyMeta.address_text_district = this.property.address_text_district;
      this.propertyMeta.address_text_city = this.property.address_text_city;
      this.propertyMeta.address_text_street = this.property.address_text_street;
      this.propertyMeta.address_text_zip = this.property.address_text_zip;
      this.form.meta = this.property.meta;
    }
    if (this.applicantId !== this.form.majitel && this.coApplicantId !== this.form.majitel) {
      this.otherOwnerId = this.form.majitel;
      this.otherOwner = null;
    }
  },
  methods: {
    selectSubTypeOfProperty (subType) {
      this.subTypeData = subType;
      this.typeData = this.getPropertyDataBySubType(subType).type;
      this.removePravnyStavValue = true;
    },
    getAccordingOptions () {
      return this[this.subTypeData];
    },
    setClient (client) {
      this.otherOwner = client;
    },
    getOwnerChoices () {
      if (this.mortgage.coapplicant)
        return [
          {
            display_name: "žiadateľ",
            value: "applicant",
          },
          {
            display_name: "spolužiadateľ",
            value: "coapplicant",
          },
          {
            display_name: "tretia strana",
            value: "other",
          },
        ]
      return [
        {
          display_name: "žiadateľ",
          value: "applicant",
        },
        {
          display_name: "tretia strana",
          value: "other",
        },
      ]
    },
    setAddress (addressObject) {
      this.address = addressObject;
    },
    changeOwner (owner) {
      if (owner !== "other") {
        this.otherOwner = null;
      }
      this.ownerHelper = owner;
    },
    getOwner () {
      const owner = this.ownerHelper;
      if (owner === "applicant") return this.applicantId;
      else if (owner === "coapplicant") return this.coApplicantId;
      else if (owner === "other") return this.otherOwner?.id ? this.otherOwner.id : null;
      return null;
    },
    translateOwnershipFromDbValue () {
      const owner = this.form.majitel;
      if (owner) {
        if (this.applicantId === owner) {
          return "applicant";
        } else if (this.coApplicantId === owner) {
          return "coapplicant";
        }
      }
      return "other";
    },
    setDropdownValue (value, dropdownName) {
      this.form[dropdownName] = value;
      if (dropdownName === "vydavky") {
        this.showEstimatedDtiToUser();
      }
    },
    convertOwner () {
      if (this.ownerHelper === "coapplicant") {
        return "coApplicant"
      }
      return this.ownerHelper;
    },
    saveProperty () {
      this.createClient = true;
      const propertyObject = {
        meta: {
          childTypeAlreadySpecified: this.propertyTypeHelper !== "not_specified",
          childType: this.getPropertyDataBySubType(this.subTypeData).type,
        },
        data: {
          majitelType: this.convertOwner(),
          hodnota: this.form.hodnota,
          pravny_stav: this.form.pravny_stav,
          majitel: this.getOwner(),
          tarcha_banka: this.form.tarcha_banka,
          tarcha_orientacny_zostatok: this.form.tarcha_orientacny_zostatok,
          meta: this.form.meta,
          ...this.address,
        },
      };
      if (this.property) {
        propertyObject.id = this.property.id;
      }
      // console.log(this.form);
      // console.log(propertyObject);
      this.$emit("property-saved", propertyObject);
    },
  },
  data: () => ({
    applicantId: null,
    coApplicantId: null,
    address: null,
    typeData: null,
    subTypeData: null,
    propertyTypeHelper: null,
    removePravnyStavValue: false,
    propertyMeta: {
      cislo_lv: null,
      cislo_parcely: null,
      address_text_number: null,
      address_text_popisne: null,
      address_text_county: null,
      address_text_district: null,
      address_text_city: null,
      address_text_street: null,
      address_text_zip: null
    },
    form: {
      hodnota: null,
      pravny_stav: null,
      typ_vlastnictva: null,
      majitel: null,
      majitel_email: null,
      majitel_fisrt_name: null,
      majitel_last_name: null,
      majitel_telefon: null,
      tarcha_banka: null,
      tarcha_orientacny_zostatok: null,
      meta: null,
    },
    createClient: false,
    otherOwner: null,
    otherOwnerId: null,
    googlePlaceId: null,
    ownerHelper: null,
    mortgage: null,
    propertyTypes: [
      {
        display_name: "Rodinný dom / chata",
        value: "house",
      },
      {
        display_name: "Byt / Apartmán",
        value: "flatInFlat",
      },
      {
        display_name: "Byt v rodinnom dome",
        value: "flatInHouse",
      },
      {
        display_name: "Pozemok",
        value: "land",
      },
    ],
    flatInHouse: [
      {
        display_name: "skolaudovaný",
        value: "skolaudovana",
      },
      {
        display_name: "rozostavaný",
        value: "rozostavana",
      }
    ],
    flatInFlat: [
      {
        display_name: "skolaudovaný",
        value: "skolaudovana",
      },
      {
        display_name: "developerský projekt",
        value: "developersky_projekt",
      }
    ],
    house: [
      {
        display_name: "skolaudovaný",
        value: "skolaudovana",
      },
      {
        display_name: "rozostavaný",
        value: "rozostavana",
      }
    ],
    land: [
      {
        display_name: "stavebny pozemok",
        value: "stavebny_pozemok",
      },
      {
        display_name: "pozemok bez stavebka",
        value: "pozemok",
      }
    ]
  }),
};
</script>

<style lang="scss" scoped></style>
